<script>
    import V from 'voUtils/V.js'



    export default {
    	voVueComponent: 'feedback-carousel',
    	props: {
    		carouselId: String
    	},

    	mounted: function() {
    		$("#owl-" + this.carouselId).owlCarousel({
    			items: 1,
    			loop: true,
    			autoplay: true,
    			dots: true,
    			smartSpeed: 3000,
    			nav: false,
    			autoplayTimeout: 10000
    		});
    	}
    };
</script>